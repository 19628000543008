export default {
  search: 'Qidirish',
  heading: 'Katalog orqali qidirish',
  selectPlaceholder1: 'Turkum',
  selectPlaceholder2: 'Material',
  priceInputPlaceholder1: 'Hajm dan',
  priceInputPlaceholder2: 'Hajm gacha',
  searchBtn: 'Qidirish',
  dateTo: 'Sana gacha',
  dateFrom: 'Sana dan',
  barcode: 'Shtrix-kod',
  scanBarcode: 'Shtrix-kodni skanerlang',
  scannedBarcode: 'Skanlangan shtrix-kod',
  noBarcode: 'Hali hech qanday shtrix-kod skanerlanmagan.',
  cameraDenied: 'Kameraga ruxsat bloklangan!',
}