export default {
  search: 'Search',
  heading: 'Search in catalog',
  selectPlaceholder1: 'Category',
  selectPlaceholder2: 'Material',
  priceInputPlaceholder1: 'Volume from',
  priceInputPlaceholder2: 'Volume to',
  searchBtn: 'Search products',
  dateTo: 'Date to',
  dateFrom: 'Date from',
  barcode: 'Barcode',
  scanBarcode: 'Scan a Barcode',
  scannedBarcode: 'Scanned Barcode',
  noBarcode: 'No barcode scanned yet.',
  cameraDenied: 'Camera access blocked!',
}