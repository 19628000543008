export default {
  search: 'Поиск',
  heading: 'Поиск по каталогу',
  selectPlaceholder1: 'Категория',
  selectPlaceholder2: 'Материал',
  priceInputPlaceholder1: 'Объем от',
  priceInputPlaceholder2: 'Объем до',
  searchBtn: 'Найти товары',
  dateTo: 'Дата до',
  dateFrom: 'Дата от',
  barcode: 'Штрих-код',
  scanBarcode: 'Сканировать штрих-код',
  scannedBarcode: 'Сканированный штрих-код',
  noBarcode: 'Штрих-код пока не сканировался.',
  cameraDenied: 'Доступ к камере заблокирован!',
}